// PrimeVue package must be installed per product

// PrimeVue
import PrimeVue from 'primevue/config';

import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import AutoComplete from 'primevue/autocomplete';
import Badge from 'primevue/badge';
import BlockUI from 'primevue/blockui';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DatePicker from 'primevue/datepicker';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Drawer from 'primevue/drawer';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import IconField from 'primevue/iconfield';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputIcon from 'primevue/inputicon';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import MultiSelect from 'primevue/multiselect';
import OverlayBadge from 'primevue/overlaybadge';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import Popover from 'primevue/popover';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import ScrollPanel from 'primevue/scrollpanel';
import Select from 'primevue/select';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import TieredMenu from 'primevue/tieredmenu';
import Toast from 'primevue/toast';
import ToggleButton from 'primevue/togglebutton';
import ToggleSwitch from 'primevue/toggleswitch';
import Tooltip from 'primevue/tooltip';
import VirtualScroller from 'primevue/virtualscroller';

// font family and minor tweaks
import './base.css';

// directives and services
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

// custom components
import SwBlockUI from '../components-vue3/general/BlockUI.vue';
import SwDialogButtonWrapper from '../components-vue3/general/DialogButtonWrapper.vue';
import SwCollapsible from '../components-vue3/general/Collapsible.vue';
import SwMessage from '../components-vue3/general/Message.vue';
import SwCol from '../components-vue3/layout/Col.vue';
import SwFormGroup from '../components-vue3/form/FormGroup.vue';
import SwRow from '../components-vue3/layout/Row.vue';
import SwSimpleDropdown from '../components-vue3/SimpleDropdown.vue';

// custom directives
import SidebarDirective from './directives/sidebar';

// custom services
import PromiseConfirm from './services/promiseConfirm';

// SupWiz preset for PrimeVue
import primevuePreset from './primevue-preset';

export default {
  // eslint-disable-next-line no-unused-vars
  install: (app, options) => {
    // add primevue
    app.use(PrimeVue, {
      ripple: true,
      theme: {
        preset: primevuePreset,
        options: {
          cssLayer: {
            name: 'primevue',
            order: 'tailwind-base, primevue, tailwind-utilities',
          },
          darkModeSelector: 'none', // needs a bit more work to behave correctly
        },
      },
    })

      // add components, this can be automated with vite but we use webpack... for now...
      // PrimeVue Services
      .use(ConfirmationService)
      .use(ToastService)
      // PrimeVue components
      .component('PvAccordion', Accordion)
      .component('PvAccordionContent', AccordionContent)
      .component('PvAccordionHeader', AccordionHeader)
      .component('PvAccordionPanel', AccordionPanel)
      .component('PvAutoComplete', AutoComplete)
      .component('PvBadge', Badge)
      .component('PvBlockUI', BlockUI)
      .component('PvBreadcrumb', Breadcrumb)
      .component('PvButton', Button)
      .component('PvButtonGroup', ButtonGroup)
      .component('PvCard', Card)
      .component('PvCheckbox', Checkbox)
      .component('PvChip', Chip)
      .component('PvColumn', Column)
      .component('PvConfirmDialog', ConfirmDialog)
      .component('PvConfirmPopup', ConfirmPopup)
      .component('PvDataTable', DataTable)
      .component('PvDataView', DataView)
      .component('PvDatePicker', DatePicker)
      .component('PvDialog', Dialog)
      .component('PvDivider', Divider)
      .component('PvDrawer', Drawer)
      .component('PvFieldset', Fieldset)
      .component('PvFileUpload', FileUpload)
      .component('PvIconField', IconField)
      .component('PvInputGroup', InputGroup)
      .component('PvInputGroupAddon', InputGroupAddon)
      .component('PvInputIcon', InputIcon)
      .component('PvInputMask', InputMask)
      .component('PvInputNumber', InputNumber)
      .component('PvInputText', InputText)
      .component('PvListbox', Listbox)
      .component('PvMegaMenu', MegaMenu)
      .component('PvMenu', Menu)
      .component('PvMultiSelect', MultiSelect)
      .component('PvOverlayBadge', OverlayBadge)
      .component('PvPaginator', Paginator)
      .component('PvPanel', Panel)
      .component('PvPanelMenu', PanelMenu)
      .component('PvPassword', Password)
      .component('PvPopover', Popover)
      .component('PvProgressBar', ProgressBar)
      .component('PvProgressSpinner', ProgressSpinner)
      .component('PvRadioButton', RadioButton)
      .component('PvScrollPanel', ScrollPanel)
      .component('PvSelect', Select)
      .component('PvSelectButton', SelectButton)
      .component('PvSlider', Slider)
      .component('PvSplitter', Splitter)
      .component('PvSplitterPanel', SplitterPanel)
      .component('PvTab', Tab)
      .component('PvTabList', TabList)
      .component('PvTabPanel', TabPanel)
      .component('PvTabPanels', TabPanels)
      .component('PvTabs', Tabs)
      .component('PvTag', Tag)
      .component('PvTextarea', Textarea)
      .component('PvTieredMenu', TieredMenu)
      .component('PvToast', Toast)
      .component('PvToggleButton', ToggleButton)
      .component('PvToggleSwitch', ToggleSwitch)
      .component('PvVirtualScroller', VirtualScroller)
      // PrimeVue directives
      .directive('ripple', Ripple)
      .directive('tooltip', Tooltip)
      // supwiz-made components
      .component('SwBlockUI', SwBlockUI)
      .component('SwCol', SwCol)
      .component('SwCollapsible', SwCollapsible)
      .component('SwFormGroup', SwFormGroup)
      .component('SwMessage', SwMessage)
      .component('SwRow', SwRow)
      .component('SwSimpleDropdown', SwSimpleDropdown)
      .component('SwDialogButtonWrapper', SwDialogButtonWrapper)
      // supwiz-made directives
      .directive('sidebar', SidebarDirective)
      // supwiz-made services
      .use(PromiseConfirm);
  },
};
