<template>
  <div
    :aria-invalid="state !== null ? state === false : undefined"
    :class="wrapClasses"
    role="group"
  >
    <label
      v-if="label"
      :class="['mb-1', { 'text-sm': labelSize === 'sm' }]"
      :for="labelFor || undefined"
    >
      {{ label }}
    </label>
    <slot />
    <SwMessage
      v-if="state === false && (invalidFeedback || invalidFeedbackSlot)"
      class="my-1"
      severity="error"
      :pt="{
        text: () => ({ class: 'text-sm' }),
      }"
    >
      <slot
        v-if="state === false"
        name="invalid-feedback"
      >
        {{ invalidFeedback }}
      </slot>
    </SwMessage>
    <SwMessage
      v-else-if="state === true && (validFeedback || validFeedbackSlot)"
      class="my-1"
      severity="success"
      :pt="{
        text: () => ({ class: 'text-sm' }),
      }"
    >
      <slot
        v-if="state === true"
        name="valid-feedback"
      >
        {{ validFeedback }}
      </slot>
    </SwMessage>
    <small v-if="description" class="text-sm">{{ description }}</small>
    <small v-else-if="descriptionSlot" class="text-sm mt-1">
      <slot name="description" />
    </small>
  </div>
</template>

<script>
import { useSlots } from 'vue';

const propStringDefaultEmpty = {
  type: String,
  default: '',
};

export default {
  name: 'FormGroup',
  props: {
    wrapClasses: {
      type: String,
      default: 'flex flex-col mb-4',
    },
    label: propStringDefaultEmpty,
    labelFor: propStringDefaultEmpty,
    labelSize: {
      type: String,
      default: 'md',
    },
    description: propStringDefaultEmpty,
    validFeedback: propStringDefaultEmpty,
    invalidFeedback: propStringDefaultEmpty,
    state: {
      type: [Boolean, null],
      default: null,
    },
  },
  setup() {
    const slots = useSlots();
    return { slots };
  },
  computed: {
    invalidFeedbackSlot() {
      return this.slots['invalid-feedback'];
    },
    validFeedbackSlot() {
      return this.slots['valid-feedback'];
    },
    descriptionSlot() {
      return this.slots.description;
    },
  },
};
</script>
