import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import apiactions from '@/backend/authBackend';
import TokenStatus from '@/enums/tokenStatus';
import { objToCamel } from '@/js/utils';
import { authHeader } from '@/helpers/authHeader';
import { endpoints } from '@/backend/endpoints';
import app from '@/app';

function initializeTokenStatus(key) {
  const token = localStorage.getItem(key);
  if (!token) {
    return null;
  }
  return jwtDecode(token).exp > ((Date.now() / 1000) + 10)
    ? TokenStatus.ACCESS_TOKEN_STILL_VALID : null;
}

const initialState = {
  errorCode: null,
  tokenStatus: initializeTokenStatus('accessToken'),
  // We persist tokens to localStorage such that they can be obtained again when user refreshes page
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  refreshTimer: null,
  loggedIn: false,
  socketReconnectionFailed: false,
  user: null,
  groups: {},
  isFetchingGroups: false,
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    accessToken: (state) => state.accessToken,
    tokenSeemsOk: (state) => state.tokenStatus === TokenStatus.ACCESS_TOKEN_STILL_VALID,
    socketReconnectionFailed: (state) => state.socketReconnectionFailed,
    promptForLogout: (state, getters) => !getters.tokenSeemsOk || state.socketReconnectionFailed,
    errorCode: (state) => state.errorCode,
    loggedIn: (state) => state.loggedIn,
    displayName(state) {
      if (state.user === null) {
        return 'Missing name';
      }
      if (state.user.firstName && state.user.lastName) {
        return `${state.user.firstName} ${state.user.lastName}`;
      }
      return state.user.username;
    },
    isSuperuser: (state) => !!state.user?.isSuperuser,
  },
  mutations: {
    updateAccessToken(state, newAccessToken) {
      localStorage.setItem('accessToken', newAccessToken);
      state.accessToken = newAccessToken;
    },
    updateRefreshToken(state, newRefreshToken) {
      localStorage.setItem('refreshToken', newRefreshToken);
      state.refreshToken = newRefreshToken;
    },
    updateTokenStatus(state, newTokenStatus) {
      state.tokenStatus = newTokenStatus;
    },
    loginSuccess(state) {
      state.errorCode = null;
      state.loggedIn = true;
    },
    updateLoggedIn(state, payload) {
      state.loggedIn = payload;
    },
    loginError(state, errorCode) {
      state.errorCode = errorCode;
    },
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.errorCode = null;
      state.loggedIn = false;
      clearTimeout(state.refreshTimer);
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setGroups(state, groups) {
      groups.forEach((e) => {
        state.groups[e.id] = e;
      });
    },
    setIsFetchingGroups(state, value) {
      state.isFetchingGroups = value;
    },
  },
  actions: {
    async login({ dispatch, commit }, { username, password }) {
      let success = false;
      try {
        const response = await apiactions.login(username, password);
        if (response.status === 'success') {
          const tokens = response.tokens;
          const accessToken = tokens.access;
          commit('updateAccessToken', accessToken);
          commit('updateRefreshToken', tokens.refresh);
          commit('updateTokenStatus', TokenStatus.ACCESS_TOKEN_STILL_VALID);
          commit('loginSuccess');
          commit('updateLoggedIn', true);
          success = true;
          dispatch('scheduleNextTokenRefresh');
        } else if (response.status === 'error') {
          commit('loginError', response?.response?.status || 500);
          commit('updateTokenStatus', TokenStatus.NO_TOKEN);
        } else {
          console.log('Unhandled status ', response);
          commit('loginError', 500);
          commit('updateTokenStatus', TokenStatus.NO_TOKEN);
        }
      } catch (error) {
        app.config.globalProperties.$log.debug('Error invoking API: ', error);
        commit('loginError', 500);
        commit('updateTokenStatus', TokenStatus.NO_TOKEN);
      }
      return { success };
    },
    async getUser({ commit, dispatch }) {
      try {
        const config = { headers: authHeader() };
        const { data } = await axios.get(endpoints.userDetails, config);
        commit('setUser', objToCamel(data));
      } catch (error) {
        dispatch('templateStore/templateSendNotification', {
          title: 'Failed to fetch user details',
          text: error.message,
          severity: 'error',
          toast: true,
        }, { root: true });
      }
    },
    scheduleNextTokenRefresh({ state, commit, dispatch }) {
      const accessTokenRemainingSeconds = jwtDecode(state.accessToken).exp - (Date.now() / 1000);
      // Refresh access token 30 seconds before it expires
      const scheduleRefreshWhen = (accessTokenRemainingSeconds - 30) * 1000;
      clearTimeout(state.refreshTimer);
      state.refreshTimer = setTimeout(
        async () => {
          await dispatch('refreshTokenIfNeededAndPossible');
          const reschedule = state.tokenStatus === TokenStatus.ACCESS_TOKEN_STILL_VALID;
          if (reschedule) {
            dispatch('scheduleNextTokenRefresh');
          } else {
            commit('logout');
            commit('setUser', null);
            // Major hack: We cannot import router as it creates cyclic imports
            app.config.globalProperties.$router.push({ name: 'login' });
          }
        },
        scheduleRefreshWhen,
      );
    },
    async logout({ commit }) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      commit('logout');
      commit('setUser', null);
    },
    async refreshTokenIfNeededAndPossible({ commit, state }) {
      const encodedAccessToken = state.accessToken;
      const encodedRefreshToken = state.refreshToken;
      if (encodedAccessToken === null || encodedRefreshToken === null) {
        commit('updateTokenStatus', TokenStatus.NO_TOKEN);
        return;
      }

      const nowMili = (Date.now() / 1000);
      const accessTokenExpiryRemainingSeconds = jwtDecode(encodedAccessToken).exp - nowMili;
      const refreshTokenExpiryRemainingSeconds = jwtDecode(encodedRefreshToken).exp - nowMili;

      if (accessTokenExpiryRemainingSeconds < 600) {
        // Access token expires within next 10 minutes

        // 10 seconds is a very conservative guesstimate on the upperbound of the time it takes to
        // refresh the access-token.
        const upperBoundTokenrefreshTimeSeconds = 10;
        if (refreshTokenExpiryRemainingSeconds > upperBoundTokenrefreshTimeSeconds) {
          // Cool, we can still make a refresh: Refresh access-token using refresh-token
          try {
            const response = await apiactions.refreshAccessToken(encodedRefreshToken);
            const newAccessToken = response.data.access;
            localStorage.setItem('accessToken', newAccessToken);
            commit('updateAccessToken', newAccessToken);
            commit('updateTokenStatus', TokenStatus.ACCESS_TOKEN_STILL_VALID);
            return;
          } catch {
            commit('updateTokenStatus', TokenStatus.ERROR_REFRESHING_TOKEN);
            return;
          }
        }
        // Access-token is expired and we don't have time for a last refresh
        commit('updateTokenStatus', TokenStatus.NO_TIME_FOR_REFRESH);
        return;
      }
      // No need to do anything now: Access-token won't expire within the next 10 minutes
      commit('updateTokenStatus', TokenStatus.ACCESS_TOKEN_STILL_VALID);
    },
    async fetchGroups({ commit, dispatch }) {
      try {
        commit('setIsFetchingGroups', true);
        const config = { headers: authHeader() };
        const resp = await axios.get(endpoints.groups, config);
        commit('setGroups', resp.data);
      } catch (error) {
        dispatch('templateStore/templateSendNotification', {
          title: 'Failed to fetch groups',
          text: error.message,
          severity: 'error',
          toast: true,
        }, { root: true });
      } finally {
        commit('setIsFetchingGroups', false);
      }
    },
  },
};
