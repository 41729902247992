<template>
  <div
    :class="[{
      'min-w-60 max-w-60': !sidebarCompact,
      'min-w-16 max-w-16': sidebarCompact,
    }, 'relative flex items-center justify-center px-4']"
  >
    <PuzzelCompactLogo v-if="sidebarCompact" class="min-w-6 max-w-6" />
    <PuzzelLogo v-else class="w-3/5" />
    <div
      v-if="toolDetails.env"
      class="fixed top-0 left-0 right-0 h-1 uppercase"
      :class="envIndicatorBg"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PuzzelLogo from './PuzzelLogo.vue';
import PuzzelCompactLogo from './PuzzelCompactLogo.vue';

export default {
  name: 'LogoText',
  components: {
    PuzzelLogo,
    PuzzelCompactLogo,
  },
  computed: {
    ...mapState('templateStore', ['toolDetails', 'sidebarCompact']),
    toolVersionEnv() {
      const { version, env } = this.toolDetails;
      if (version) return version;
      if (env) return env;
      return '';
    },
    emphasizeEnvironment() {
      const { env } = this.toolDetails;
      return /test|dev/i.test(env);
    },
    envIndicatorBg() {
      const env = this.toolDetails.env ? this.toolDetails.env.toLowerCase() : '';
      if (env.includes('test')) return 'bg-warning-500';
      if (env.includes('dev')) return 'bg-red-500';
      return '';
    },
  },
};
</script>
