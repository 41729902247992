<template>
  <PvButton
    aria-haspopup="true"
    :aria-controls="menuId"
    type="button"
    v-bind="$attrs"
    @click="toggle"
  >
    <slot name="button-content" />
  </PvButton>
  <PvMenu :id="menuId" ref="menu" v-bind="{ model }" popup>
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot :name="slot" v-bind="scope" />
    </template>
  </PvMenu>
</template>

<script>
export default {
  name: 'SwButtonDropdown',
  inheritAttrs: false,
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  computed: {
    menuId() {
      // if this component stops working then this is probably why
      return `menu-${this.$.uid}`;
    },
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  },
};
</script>
