<template>
  <div class="relative" :aria-busy="blocked">
    <slot />
    <Transition
      enter-from-class="opacity-0"
      enter-active-class="duration-300"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-active-class="duration-300"
      leave-to-class="opacity-0"
    >
      <div
        v-if="blocked"
        :class="[
          'inset-0 z-50 bg-white/70 flex flex-col items-center justify-center',
          'overflow-hidden gap-4',
          {
            fixed: fullScreen,
            absolute: !fullScreen,
          },
          overlayClass,
        ]"
      >
        <PvProgressSpinner
          v-if="showSpinner"
          :class="[
            spinnerClass,
            $options.spinnerSize[spinnerSize],
          ]"
        />
        <slot name="text" />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'SwBlockUI',
  inheritAttrs: false,
  spinnerSize: {
    xs: 'size-3',
    sm: 'size-8',
    md: 'size-16',
    lg: 'size-24',
    xl: 'size-36',
  },
  props: {
    blocked: {
      type: Boolean,
      required: true,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    overlayClass: {
      type: String,
      default: 'rounded-xl',
    },
    spinnerClass: {
      type: String,
      default: '',
    },
    spinnerSize: {
      type: String,
      default: 'md',
    },
  },
};
</script>
